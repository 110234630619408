
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class VehicleCapture extends Vue {
  private dialogFormVisible = false
  private tableData = {
    loading: false,
    tr: [
      {
        label: '设备串号',
        prop: 'deviceNumber',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '车牌号码',
        prop: 'licensePlate',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '车牌颜色',
        prop: 'licensePlateColor',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '采集时间',
        prop: 'collectTime',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private date = ''
  private info = {
    deviceNumber: '',
    licensePlateColor: '',
    licensePlate: ''
  }

  page = 1
  size = 10
  total = 0

  private detailData = {
    collectTime: '',
    licensePlateColor: '',
    licensePlate: '',
    deviceNumber: '',
    licensePlatePic: '',
    vehiclePic: ''
  }

  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    const info = this.date ? {
      ...this.info,
      startTime: this.date[0],
      endTime: this.date[1]
    } : this.info
    this.$axios.get(this.$apis.vehicle.selectVehicleDataPage, {
      ...info,
      projectId: this.$route.params.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  openDetail (row: any) {
    this.detailData = row
    this.dialogFormVisible = true
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
